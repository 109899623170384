import React from 'react';
import './App.css';
import alert from './images/alert.png';

const AlertaWindows95 = ({ onYes, onNo }) => {
  return (
    <div className="alerta-overlay">
      <div className="alerta-windows95">
        <div className="alerta-cabecalho">
          <span className="alerta-titulo">Warning message</span>
          {/* Removido o botão de fechar para simplificar */}
        </div>
        <div className="alerta-corpo">
          <img src={alert} alt="Warning" className="alerta-icone" />
          <div className="alerta-mensagem">
            LOOKING FOR UTILITIES?
          </div>
        </div>
        <div className="alerta-rodape">
          <button onClick={onYes} className="alerta-botao">Yes</button>
          <button onClick={onNo} className="alerta-botao">No</button>
        </div>
      </div>
    </div>
  );
};

export default AlertaWindows95;
