import React, { useState } from 'react';
import './App.css';
import AlertaWindows95 from './AlertaWindows95';
import alert from './images/pizza.gif';
import why from './images/why.mp4';

function App() {
  const [mostrarAlerta, setMostrarAlerta] = useState(true);

  const handleYes = () => {
    setMostrarAlerta(false);
    window.location.href = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ&t=5s';
  };

  const handleNo = () => {
    setMostrarAlerta(false);
  };

  const handleBuyButtonClick = () => {
    window.location.href = 'https://raydium.io/swap/?inputCurrency=EiZBBcvpEqCeXJSoUJhU8y8v4RdBnLxZH8KrzDpmUN2d&outputCurrency=sol&outputSymbol=UTIL&inputAmount=2000000&fixed=in';
  };

  const [showLinks, setShowLinks] = useState(false);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  return (
    <div className="App">
      {mostrarAlerta && (
        <div className="alerta-windows95">
          <AlertaWindows95 onYes={handleYes} onNo={handleNo} />
        </div>
      )}
      <div className='main-page'>
        <div className='title'>
          WELCOME TO UTILITY TOKEN
        </div>

        <div className='description'>
          When your founder claims to solve world hunger, decode alien messages or brew coffee, but all you wanted was to buy pizza.
          <img src={alert} alt="Warning" className="pizza-gif" />
        </div>

        <div className='buttons'>
          <button className='buybutton' onClick={handleBuyButtonClick}>BUY $UTIL</button>
          <button className='vibebutton' onClick={toggleLinks}>VIBE $UTIL</button>
          {showLinks && (
            <div className='links-dropdown'>
              <a href="https://twitter.com/therealutility" target="_blank" rel="noopener noreferrer">Twitter</a>
              <a href="https://t.me/therealutility" target="_blank" rel="noopener noreferrer">Telegram</a>
            </div>
          )}
        </div> 

        <div className='why-utility'>
          <u>WHY UTILITY?</u>
        </div>

        <div>
        <video className='why-video' controls>
          <source src={why} type="video/mp4"/>          
          Your browser does not support HTML5 videos.
        </video>
        </div>
      </div>
    </div>
  );
}

export default App;
